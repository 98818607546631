//
//  Tooltip
//


// Base
.tooltip {
    --#{$prefix}tooltip-color: var(--kt-tooltip-color);
    --#{$prefix}tooltip-bg: var(--kt-tooltip-bg);
    --#{$prefix}tooltip-opacity: var(--kt-tooltip-opacity);

    .tooltip-inner {
        box-shadow: var(--kt-tooltip-box-shadow);
    }

    &.tooltop-auto-width {
        .tooltip-inner {
            white-space: nowrap;
            max-width: none;
        }
    }

    // Inverse
    &.tooltip-inverse {
        @include tooltip-theme($bg-color: var(--kt-dark),
            $color: var(--kt-dark-inverse),
            $arrow-color: var(--kt-dark));
    }
}